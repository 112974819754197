import './scss/styles.scss'

const top = 100
const navbar = document.querySelector('#topMenu')
const hamburger = document.querySelector('#switchMobileMenu')
const internalLinks = document.getElementsByClassName('internal-link')

function toggleMobileMenu() {
  navbar.classList.toggle('visible')
}

function closeMobileMenu() {
  navbar.classList.remove('visible')
}

function stickynavbar() {
  if (window.scrollY > top) {
    navbar.classList.add('sticky')
  } else {
    navbar.classList.remove('sticky')
  }
}

// function scrollToDiv(elementId) {
//   console.log(elementId)
//   const e = document.getElementById(elementId)
//   setTimeout(function () {
//     e.scrollIntoView({ behavior: 'smooth', block: 'start' })
//   }, 100)
// }

window.addEventListener('scroll', stickynavbar)
hamburger.addEventListener('click', toggleMobileMenu)
for (let i = 0; i < internalLinks.length; i++) {
  internalLinks[i].addEventListener('click', closeMobileMenu, false)
}
